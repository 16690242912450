<template>
  <div>
    <h1 class="h2">承認経路設定</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
    </div>

    <v-form v-model="valid" ref="form" @submit.prevent="submit">
      <template>
        <v-autocomplete
          v-model="selectFilterAffiliations"
          :items="filterAffiliations"
          :disabled="edit"
          label="事業所コード"
          :rules="[Rules.Required]"
          outlined
          multiple
          chips
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <v-autocomplete
          v-model="approvalA"
          :items="incodes"
          label="承認A"
          outlined
          clearable
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <v-autocomplete
          v-model="approvalB"
          :items="incodes"
          label="承認B"
          outlined
          clearable
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <v-autocomplete
          v-model="approvalC"
          :items="incodes"
          label="承認C"
          outlined
          clearable
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <v-autocomplete
          v-model="approvalD"
          :items="incodes"
          label="承認D"
          outlined
          clearable
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
      </template>

      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>
<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import ApprovalCharge from "@/models/ApprovalCharge";
import { CODE_GROUP as CodeGroup } from "@/defines";
export default {
  name: "ApprovalDetails",
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: false,
      incodes: [],
      filterAffiliations: [],
      selectFilterAffiliations: [],
      approvalA: { code: "" },
      approvalB: { code: "" },
      approvalC: { code: "" },
      approvalD: { code: "" },
      targetItems: [],
      sort: null
    };
  },
  methods: {
    complete() {
      console.log("complete", this.kbn);
      if (!this.$refs.form.validate()) return;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit");
      this.$loading();
      try {
        const params = new ApprovalCharge(
          this.selectFilterAffiliations,
          this.approvalA !== null ? this.approvalA.code : "",
          this.approvalB !== null ? this.approvalB.code : "",
          this.approvalC !== null ? this.approvalC.code : "",
          this.approvalD !== null ? this.approvalD.code : ""
        );
        await this.$post(this.Paths.approvalList, params);
        this.$info("更新しました。");
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    back() {
      const params = {
        selectDivision: this.params.args.selectDivision,
        selectFilterAffiliations: this.params.args.selectFilterAffiliations
      };
      this.$router.push({ name: "ApprovalList", params });
    },
    async getUsers() {
      console.log("getUsers");
      return await this.$get(this.Paths.shainSorted);
    }
  },
  async created() {
    console.log("created", this.$route.params, this.args);
    this.params = this.$route.params;
    console.log(this.params);
    this.$loading();
    try {
      if (this.params.prosess && typeof this.params.prosess === "string")
        this.params.prosess = this.params.prosess.toLowerCase();

      switch (this.params.prosess) {
        case "add":
          this.add = true;
          this.edit = false;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }
      const code1 = this.$store.state.shozokuCode1;

      this.affiliationsItems = await this.$get(this.Paths.shozoku);
      this.filterAffiliations = this.affiliationsItems;
      if (code1 && code1 !== "9") {
        this.filterAffiliations = this.affiliationsItems.filter(
          e => e.refcode === this.$store.state.shozokuCode1
        );
      }
      this.incodes = await this.getUsers();

      if (!this.add) {
        const incodes = [];
        this.params.args.targetItems.forEach(t => {
          incodes.push(String(t.affiliationId));
        });
        this.selectFilterAffiliations = incodes;
        console.log(this.selectFilterAffiliations);
      }

      if (this.add || this.params.args.targetItems.length > 1) {
        if (code1 && code1 !== "9") {
          const codeMasters = await this.$get(this.Paths.codeMaster);
          const approvals = codeMasters.items.filter(
            e => e.kbn === CodeGroup.APPRVL && e.comcha1 === code1
          );
          if (approvals.length > 0) {
            const userC = approvals.filter(e => e.comcha2 === "C");
            this.approvalC = { code: userC.length > 0 ? userC[0].code : "" };
            const userD = approvals.filter(e => e.comcha2 === "D");
            this.approvalD = { code: userD.length > 0 ? userD[0].code : "" };
          }
        }
      } else {
        if (!this.add) {
          this.approvalA = {
            code:
              this.params.args.targetItems[0].approvalA !== null
                ? String(this.params.args.targetItems[0].approvalA)
                : ""
          };
          this.approvalB = {
            code:
              this.params.args.targetItems[0].approvalB !== null
                ? String(this.params.args.targetItems[0].approvalB)
                : ""
          };
          this.approvalC = {
            code:
              this.params.args.targetItems[0].approvalC !== null
                ? String(this.params.args.targetItems[0].approvalC)
                : ""
          };
          this.approvalD = {
            code:
              this.params.args.targetItems[0].approvalD !== null
                ? String(this.params.args.targetItems[0].approvalD)
                : ""
          };
        }
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style></style>
